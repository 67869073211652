var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.icon)?_c('CyTooltip',_vm._b({attrs:{"disabled":!_vm.showTooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.isSvg)?_c(_vm.icon,_vm._g({tag:"component",class:['credential-icon credential-icon--svg', {
        'credential-icon--greyscale': _vm.greyscale,
        'credential-icon--no-margin': _vm.noMargin,
      }],attrs:{"height":_vm.size,"width":_vm.size,"alt":_vm.tooltipText}},on)):(_vm.isImage)?_c('img',_vm._g({class:['credential-icon credential-icon--image', {
        'credential-icon--greyscale': _vm.greyscale,
        'credential-icon--no-margin': _vm.noMargin,
      }],attrs:{"src":_vm.icon,"alt":_vm.tooltipText,"width":_vm.size}},on)):_c('v-icon',_vm._g(_vm._b({class:['credential-icon credential-icon--icon', {
        'credential-icon--greyscale': _vm.greyscale,
        'credential-icon--no-margin': _vm.noMargin,
      }],style:(("width: " + _vm.size + "px;"))},'v-icon',{ size: _vm.size },false),on),[_vm._v(" "+_vm._s(_vm.icon)+" ")])]}}],null,false,2301368058)},'CyTooltip',( _obj = {}, _obj[_vm.tooltipDirection] = true, _obj ),false),[_vm._v(" "+_vm._s(_vm.tooltipText)+" ")]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }